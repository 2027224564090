import React from 'react';
import Layout from '../layouts/Layout';
import SmallHero from '../components/SmallHero/SmallHero';
import JobAlerts from '../components/JobAlerts/JobAlerts';
// @ts-ignore
import arrowRight from '../images/arrow-right-white.svg';
import './index.css';

// @ts-ignore
import backArrow from '../images/blue-arrow-left.svg';

const JobDescription = ({ pageContext }: any) => {
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<SmallHero h1={'Job Listings'} h2={pageContext.title} />
			<section className={'bg-bt-light-gray px-3 py-14'}>
				<div className={'container mx-auto'}>
					<a
						href="/search"
						className="font-rubik text-[20px] text-bt-blue"
					>
						<button className="hover:underline">
							<img
								src={backArrow}
								width={16}
								className="float-left mt-1 mr-[6px]"
							/>
							Back
						</button>
					</a>
					<div
						className={
							'mt-6 flex flex-row justify-between max-md:flex-col-reverse'
						}
					>
						<div
							className={
								'job-description-content flex w-3/4 flex-col max-md:w-full'
							}
						>
							<h2
								className={
									'font-rubik text-4xl font-normal text-bt-dark-gray'
								}
							>
								Job Description
							</h2>
							<p
								dangerouslySetInnerHTML={{
									__html: pageContext.description
								}}
							/>
							{/*<h2*/}
							{/*	className={*/}
							{/*		'font-rubik text-4xl font-normal text-bt-dark-gray'*/}
							{/*	}*/}
							{/*>*/}
							{/*	About Us*/}
							{/*</h2>*/}
							{/*<p className={'font-rubik text-xl text-bt-gray'}>*/}
							{/*	Are you looking for a new challenge and a place*/}
							{/*	to grow your career? We have a great opportunity*/}
							{/*	available at Belle Tire! We offer top pay and*/}
							{/*	great benefits. Apply today!*/}
							{/*</p>*/}
							{/*<p className={'font-rubik text-xl text-bt-gray'}>*/}
							{/*	Belle Tire was established 100 years ago with*/}
							{/*	the belief that customer satisfaction must*/}
							{/*	always be the bottom line. This year we*/}
							{/*	celebrate 100 years in business, the*/}
							{/*	extraordinary team that got us here and the team*/}
							{/*	that will carry us into the next 100 years.*/}
							{/*</p>*/}
							{/*<p className={'font-rubik text-xl text-bt-gray'}>*/}
							{/*	As a family owned company with over 130 retail*/}
							{/*	locations and over 2,000 employees in Michigan,*/}
							{/*	Indiana, Illinois and Ohio, we give our*/}
							{/*	neighbors peace of mind and trust that we will*/}
							{/*	get them back on the road fast and affordably.*/}
							{/*	Belle Tire provides tire and automotive services*/}
							{/*	including shocks and struts, oil changes,*/}
							{/*	brakes, batteries, auto glass and more with an*/}
							{/*	in-store experience that far exceeds a*/}
							{/*	traditional garage.*/}
							{/*</p>*/}
							{/*<p className={'font-rubik text-xl text-bt-gray'}>*/}
							{/*	Belle Tire is an equal opportunity employer. At*/}
							{/*	Belle Tire we are committed to treating all*/}
							{/*	applicants and employees fairly based on their*/}
							{/*	abilities, achievements, and experience without*/}
							{/*	regard to race, national origin, sex, age,*/}
							{/*	religion, disability, veteran status, sexual*/}
							{/*	orientation, gender identity, or any other*/}
							{/*	classification protected by law.*/}
							{/*</p>*/}

							<a
								href={pageContext.url}
								className={'mt-6 max-w-[200px]'}
								target={'_blank'}
							>
								<button
									className={
										'ApplyButton ApplyButtonBottom flex flex-row items-center rounded-full bg-bt-blue p-3 px-5 text-white hover:bg-bt-red'
									}
								>
									<span className={'font-open-sans text-xl'}>
										APPLY NOW
									</span>

									<img
										className={'ml-3 w-5'}
										src={arrowRight}
										alt={'Right arrow'}
									/>
								</button>
							</a>
						</div>
						<div
							className={
								'flex w-1/4 flex-col px-3 pb-6 max-md:w-full max-md:px-0'
							}
						>
							<a
								href={pageContext.url}
								className={'ApplyButton ApplyButtonTop'}
								target={'_blank'}
							>
								<button
									className={
										'flex w-full flex-row items-center justify-center rounded-full bg-bt-blue p-3 px-5 text-center text-white hover:bg-bt-red'
									}
								>
									<span className={'font-open-sans text-xl'}>
										APPLY NOW
									</span>

									<img
										className={'ml-3 w-5'}
										src={arrowRight}
										alt={'Right arrow'}
									/>
								</button>
							</a>
							<div className={'mt-6 bg-white p-6'}>
								<span
									className={
										'font-open-sans text-2xl font-bold'
									}
								>
									Location:
								</span>
								<p
									className={
										'pb-6 pt-4 font-open-sans text-xl'
									}
								>
									{pageContext.city}, {pageContext.state}
								</p>
								<span
									className={
										'font-open-sans text-2xl font-bold'
									}
								>
									Category:
								</span>
								<p
									className={
										'pb-6 pt-4 font-open-sans text-xl'
									}
								>
									{pageContext.category}
								</p>
								<span
									className={
										'font-open-sans text-2xl font-bold'
									}
								>
									Req ID:
								</span>
								<p
									className={
										'pb-6 pt-4 font-open-sans text-xl'
									}
								>
									{pageContext.reqNumber}
								</p>
								<span
									className={
										'font-open-sans text-2xl font-bold'
									}
								>
									Req Number:
								</span>
								<p
									className={
										'pb-6 pt-4 font-open-sans text-xl'
									}
								>
									{pageContext.reqID}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<JobAlerts />
		</Layout>
	);
};

export default JobDescription;
